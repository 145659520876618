import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './App.css'
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import store ,{persistor} from './redux/store'
import {PersistGate} from 'redux-persist/integration/react'

ReactDOM.render(
 
  <React.StrictMode>
     <Provider store={store} >  
     <PersistGate load={null} persistor={persistor}>
      <App />
     </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
